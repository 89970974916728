var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-form-tags',{staticClass:"mb-2",attrs:{"id":"tags-component-select","size":"lg","disabled":_vm.disabled,"add-on-change":"","no-outer-focus":""},on:{"input":function($event){return _vm.$emit('input', _vm.raters)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var tags = ref.tags;
var inputAttrs = ref.inputAttrs;
var inputHandlers = ref.inputHandlers;
var disabled = ref.disabled;
var removeTag = ref.removeTag;
return [(tags.length > 0 && _vm.ratersProp.length > 0)?_c('ul',{staticClass:"list-inline d-inline-block mb-2"},_vm._l((tags),function(tag,index){return _c('li',{key:index,staticClass:"list-inline-item"},[_c('b-form-tag',{attrs:{"id":'formTag' + tag,"pill":"","title":'user_id:' + tag,"disabled":disabled,"variant":_vm.raters.find(function (val) { return val.user_id == parseInt(tag, 10); }).type ===
            'head'
              ? 'warning'
              : 'info'},on:{"remove":function($event){return removeTag(tag)}}},[_vm._v(" "+_vm._s(((_vm.options.find(function (opt) { return opt.value == tag; }).text) + " [" + (_vm.raters.find(function (val) { return val.user_id == parseInt(tag, 10); }).code) + "]"))+" ")]),_c('b-popover',{attrs:{"target":'formTag' + tag,"triggers":"click blur","placement":"auto"},on:{"shown":function($event){return _vm.popoverShown($event)}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('p',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.options.find(function (opt) { return opt.value == tag; }).text)+" ")])]},proxy:true}],null,true)},[_c('div',{staticClass:"d-flex"},[_c('label',{staticClass:"mr-2",staticStyle:{"min-width":"6rem"}},[_vm._v(" 類型 "),_c('b-form-select',{attrs:{"options":_vm.raterTypeOptions},model:{value:(
                  _vm.raters[
                    _vm.raters.findIndex(function (val) { return val.user_id == parseInt(tag, 10); })
                  ].type
                ),callback:function ($$v) {_vm.$set(_vm.raters[
                    _vm.raters.findIndex(function (val) { return val.user_id == parseInt(tag, 10); })
                  ], "type", $$v)},expression:"\n                  raters[\n                    raters.findIndex(val => val.user_id == parseInt(tag, 10))\n                  ].type\n                "}})],1),_c('label',[_vm._v(" 暗碼 "),_c('b-form-input',{attrs:{"state":_vm.targetCodeRepeatCheck(
                    _vm.raters.findIndex(function (val) { return val.user_id == parseInt(tag, 10); })
                  ),"placeholder":"輸入暗碼"},model:{value:(
                  _vm.raters[
                    _vm.raters.findIndex(function (val) { return val.user_id == parseInt(tag, 10); })
                  ].code
                ),callback:function ($$v) {_vm.$set(_vm.raters[
                    _vm.raters.findIndex(function (val) { return val.user_id == parseInt(tag, 10); })
                  ], "code", $$v)},expression:"\n                  raters[\n                    raters.findIndex(val => val.user_id == parseInt(tag, 10))\n                  ].code\n                "}}),_c('b-form-invalid-feedback',[_vm._v(" 暗碼重覆 ")])],1)])])],1)}),0):_vm._e(),_c('p',{staticClass:"text-secondary mb-1",staticStyle:{"font-size":"0.9rem"}},[_c('b-icon',{attrs:{"icon":"exclamation-circle-fill","variant":"secondary"}}),_vm._v(" 點擊 badge 可以設定評審長及暗碼 ")],1),_c('b-form-select',{attrs:{"form":inputAttrs.form,"id":inputAttrs.id,"value":inputAttrs.value,"disabled":disabled || _vm.availableOptions.length === 0,"options":_vm.availableOptions},on:{"change":function($event){return inputHandlers.change($event)},"input":function($event){return inputHandlers.input($event)},"keydown":function($event){return inputHandlers.keydown($event)}},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('option',{attrs:{"disabled":""},domProps:{"value":null}},[_vm._v("請選擇選項...")])]},proxy:true}],null,true)})]}}]),model:{value:(_vm.ids),callback:function ($$v) {_vm.ids=$$v},expression:"ids"}})}
var staticRenderFns = []

export { render, staticRenderFns }