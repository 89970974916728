<template>
  <div
    class="cardBody mx-auto my-2 d-none d-md-flex "
    :class="state === 1 && mode === 'create' ? 'hoverEffect' : ''"
  >
    <b-container v-if="mode === 'create' && state === 1" @click="state = 2">
      <b-row>
        <b-col>
          <b-icon icon="plus" font-scale="4"></b-icon>
          <h2 class="mb-1 mr-3">建立新評鑑</h2>
        </b-col>
      </b-row>
    </b-container>

    <!-- 簡單表單 -->
    <b-container v-if="mode === 'create' && state === 2">
      <b-row>
        <h2>建立新評鑑</h2>
      </b-row>
      <b-row>
        <b-col>
          <label class="text-left"
            >評鑑名稱
            <b-form-input v-model="eventData.name" trim></b-form-input>
          </label>
        </b-col>
        <b-col>
          <label class="text-left"
            >起始日期
            <b-form-datepicker
              v-model="eventData.start_time"
              class="mb-2"
              label-no-date-selected="請選日期"
            ></b-form-datepicker>
          </label>
        </b-col>
        <b-col>
          <label class="text-left"
            >結束日期
            <b-form-datepicker
              v-model="eventData.end_time"
              class="mb-2"
              label-no-date-selected="請選日期"
            ></b-form-datepicker>
          </label>
        </b-col>
        <b-col>
          <label class="text-left"
            >評鑑模式
            <b-form-select
              v-model="eventData.type"
              :options="[
                { value: 'remote', text: '線上評鑑' },
                { value: 'local', text: '現場評鑑' }
              ]"
            ></b-form-select>
          </label>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <label class="text-left"
            >主辦單位
            <b-form-input v-model="eventData.organizer" trim></b-form-input>
          </label>
        </b-col>
        <b-col>
          <label class="text-left"
            >協辦單位
            <b-form-input v-model="eventData.coorganizer" trim></b-form-input>
          </label>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <label class="text-left"
            >指導單位
            <b-form-input v-model="eventData.adviser" trim></b-form-input>
          </label>
        </b-col>
        <b-col>
          <label class="text-left"
            >承辦單位
            <b-form-input v-model="eventData.implementer" trim></b-form-input>
          </label>
        </b-col>
      </b-row>
      <b-row class="mt-2" align-h="start">
        <b-col cols="4">
          <b-btn variant="danger" @click="state = 1">
            放棄
          </b-btn>
          <b-btn class="ml-2" variant="outline-primary" @click="createEvent()">
            建立評鑑
          </b-btn>
        </b-col>
      </b-row>
    </b-container>

    <b-container v-if="mode === 'edit'">
      <b-row>
        <h2>編輯評鑑</h2>
      </b-row>
      <b-row>
        <b-col>
          <label class="text-left"
            >評鑑名稱
            <b-form-input
              v-model="eventData.name"
              trim
              :disabled="
                eventDataIN.status === 'done' ||
                  eventDataIN.status === 'ongoing'
              "
            ></b-form-input>
          </label>
        </b-col>
        <b-col>
          <label class="text-left"
            >起始日期
            <b-form-datepicker
              :disabled="
                eventDataIN.status === 'done' ||
                  eventDataIN.status === 'ongoing'
              "
              v-model="eventData.start_time"
              class="mb-2"
              label-no-date-selected="還沒選日期"
            ></b-form-datepicker>
          </label>
        </b-col>
        <b-col>
          <label class="text-left"
            >結束日期
            <b-form-datepicker
              :disabled="
                eventDataIN.status === 'done' ||
                  eventDataIN.status === 'ongoing'
              "
              v-model="eventData.end_time"
              class="mb-2"
              label-no-date-selected="還沒選日期"
            ></b-form-datepicker>
          </label>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <label class="text-left"
            >主辦單位
            <b-form-input v-model="eventData.organizer" trim></b-form-input>
          </label>
        </b-col>
        <b-col>
          <label class="text-left"
            >協辦單位
            <b-form-input v-model="eventData.coorganizer" trim></b-form-input>
          </label>
        </b-col>
        <b-col>
          <label class="text-left"
            >評鑑模式
            <b-form-select
              :disabled="
                eventDataIN.status === 'done' ||
                  eventDataIN.status === 'ongoing'
              "
              v-model="eventData.type"
              :options="[
                { value: 'remote', text: '線上評鑑' },
                { value: 'local', text: '現場評鑑' }
              ]"
            ></b-form-select>
          </label>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <label class="text-left"
            >指導單位
            <b-form-input v-model="eventData.adviser" trim></b-form-input>
          </label>
        </b-col>
        <b-col>
          <label class="text-left"
            >承辦單位
            <b-form-input v-model="eventData.implementer" trim></b-form-input>
          </label>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <label class="text-left" style="width:100%"
            >咖啡暗碼池
            <b-form-tags
              v-model="eventData.beanCodePool"
              tag-variant="primary"
              :disabled="
                eventDataIN.status === 'done' ||
                  eventDataIN.status === 'ongoing'
              "
              tag-pills
              separator=" "
              placeholder="請直接鍵入暗碼並按enter逐一建立"
              remove-on-delete
            ></b-form-tags>
          </label>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <label class="text-left" style="width:100%"
            >評審池
            <form_tags
              :disabled="
                eventDataIN.status === 'done' ||
                  eventDataIN.status === 'ongoing'
              "
              :ratersProp="eventData.ratersPool"
              @input="$set(eventData, 'ratersPool', $event)"
              :options="allUsers"
            ></form_tags>
          </label>
        </b-col>
      </b-row>
      <b-row class="mt-2" align-h="start">
        <b-col cols="4">
          <b-btn class="m-1" variant="danger" @click="$emit('quit', eventData)">
            放棄
          </b-btn>
          <b-btn
            class="m-1"
            variant="primary"
            @click="$emit('save', eventData)"
          >
            儲存
          </b-btn>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import form_tags from "./form-tags_ratersPool.vue";
import { API } from "../store/api";
export default {
  name: "EventFormCard",
  components: { form_tags },
  props: {
    eventDataIN: {
      type: Object,
      require: false
    },
    mode: {
      type: String,
      require: true,
      validator(val) {
        return ["create", "edit"].indexOf(val) !== -1;
      }
    }
  },
  watch: {
    eventDataIN: {
      deep: true,
      handler(newVal) {
        this.eventData = { ...newVal };
      }
    }
  },
  async mounted() {
    if (this.eventDataIN != null) {
      this.eventData = { ...this.eventDataIN };

      this.allUsers = (await API.GetUsers()).users.map(u => {
        return { text: u.name, value: "" + u.id };
      });

      let raters = await API.GetRaters(this.eventData.id);
      this.$set(this.eventData, "ratersPool", raters);

      let headJudge = raters.find(r => r.type === "head");
      this.$set(
        this.eventData,
        "headJudgeName",
        headJudge !== undefined ? headJudge.user_name : null
      );

      this.$set(
        this.eventData,
        "beanCodePool",
        (await API.GetTargets(this.eventData.id)).map(t => t.code)
      );
    }
  },
  data() {
    return {
      state: 1 /* state machine indicater , only two state */,
      allUsers: [],
      eventData: {
        id: null,
        name: null,
        start_time: null,
        end_time: null,
        type: "local",
        adviser: null,
        organizer: null,
        coorganizer: null,
        implementer: null,
        ratersPool: [],
        beanCodePool: [],
        headJudgeName: null
      }
    };
  },
  methods: {
    createEvent() {
      this.$emit("create", this.eventData);
      Object.keys(this.eventData).forEach(key => (this.eventData[key] = null));
      this.state = 1;
    }
  }
};
</script>

<style lang="scss" scoped>
.cardBody {
  position: relative;
  max-width: 1027px;

  padding: 1rem 2rem 1rem 1.5rem;

  background: linear-gradient(135deg, #ededed 0%, #ffffff 100%);
  box-shadow: -8px 8px 16px rgba(165, 165, 165, 0.2),
    8px -8px 16px rgba(165, 165, 165, 0.2),
    -8px -8px 16px rgba(255, 255, 255, 0.9),
    8px 8px 20px rgba(165, 165, 165, 0.9),
    inset 1px 1px 2px rgba(255, 255, 255, 0.3),
    inset -1px -1px 2px rgba(165, 165, 165, 0.5);
  box-sizing: border-box;

  border-radius: 7px;
}

.hoverEffect:hover {
  background: linear-gradient(135deg, #ffffff 0%, #ededed 100%);
}
</style>
