var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"cardBody mx-auto my-2 d-none d-md-flex ",class:_vm.state === 1 && _vm.mode === 'create' ? 'hoverEffect' : ''},[(_vm.mode === 'create' && _vm.state === 1)?_c('b-container',{on:{"click":function($event){_vm.state = 2}}},[_c('b-row',[_c('b-col',[_c('b-icon',{attrs:{"icon":"plus","font-scale":"4"}}),_c('h2',{staticClass:"mb-1 mr-3"},[_vm._v("建立新評鑑")])],1)],1)],1):_vm._e(),(_vm.mode === 'create' && _vm.state === 2)?_c('b-container',[_c('b-row',[_c('h2',[_vm._v("建立新評鑑")])]),_c('b-row',[_c('b-col',[_c('label',{staticClass:"text-left"},[_vm._v("評鑑名稱 "),_c('b-form-input',{attrs:{"trim":""},model:{value:(_vm.eventData.name),callback:function ($$v) {_vm.$set(_vm.eventData, "name", $$v)},expression:"eventData.name"}})],1)]),_c('b-col',[_c('label',{staticClass:"text-left"},[_vm._v("起始日期 "),_c('b-form-datepicker',{staticClass:"mb-2",attrs:{"label-no-date-selected":"請選日期"},model:{value:(_vm.eventData.start_time),callback:function ($$v) {_vm.$set(_vm.eventData, "start_time", $$v)},expression:"eventData.start_time"}})],1)]),_c('b-col',[_c('label',{staticClass:"text-left"},[_vm._v("結束日期 "),_c('b-form-datepicker',{staticClass:"mb-2",attrs:{"label-no-date-selected":"請選日期"},model:{value:(_vm.eventData.end_time),callback:function ($$v) {_vm.$set(_vm.eventData, "end_time", $$v)},expression:"eventData.end_time"}})],1)]),_c('b-col',[_c('label',{staticClass:"text-left"},[_vm._v("評鑑模式 "),_c('b-form-select',{attrs:{"options":[
              { value: 'remote', text: '線上評鑑' },
              { value: 'local', text: '現場評鑑' }
            ]},model:{value:(_vm.eventData.type),callback:function ($$v) {_vm.$set(_vm.eventData, "type", $$v)},expression:"eventData.type"}})],1)])],1),_c('b-row',[_c('b-col',[_c('label',{staticClass:"text-left"},[_vm._v("主辦單位 "),_c('b-form-input',{attrs:{"trim":""},model:{value:(_vm.eventData.organizer),callback:function ($$v) {_vm.$set(_vm.eventData, "organizer", $$v)},expression:"eventData.organizer"}})],1)]),_c('b-col',[_c('label',{staticClass:"text-left"},[_vm._v("協辦單位 "),_c('b-form-input',{attrs:{"trim":""},model:{value:(_vm.eventData.coorganizer),callback:function ($$v) {_vm.$set(_vm.eventData, "coorganizer", $$v)},expression:"eventData.coorganizer"}})],1)])],1),_c('b-row',[_c('b-col',[_c('label',{staticClass:"text-left"},[_vm._v("指導單位 "),_c('b-form-input',{attrs:{"trim":""},model:{value:(_vm.eventData.adviser),callback:function ($$v) {_vm.$set(_vm.eventData, "adviser", $$v)},expression:"eventData.adviser"}})],1)]),_c('b-col',[_c('label',{staticClass:"text-left"},[_vm._v("承辦單位 "),_c('b-form-input',{attrs:{"trim":""},model:{value:(_vm.eventData.implementer),callback:function ($$v) {_vm.$set(_vm.eventData, "implementer", $$v)},expression:"eventData.implementer"}})],1)])],1),_c('b-row',{staticClass:"mt-2",attrs:{"align-h":"start"}},[_c('b-col',{attrs:{"cols":"4"}},[_c('b-btn',{attrs:{"variant":"danger"},on:{"click":function($event){_vm.state = 1}}},[_vm._v(" 放棄 ")]),_c('b-btn',{staticClass:"ml-2",attrs:{"variant":"outline-primary"},on:{"click":function($event){return _vm.createEvent()}}},[_vm._v(" 建立評鑑 ")])],1)],1)],1):_vm._e(),(_vm.mode === 'edit')?_c('b-container',[_c('b-row',[_c('h2',[_vm._v("編輯評鑑")])]),_c('b-row',[_c('b-col',[_c('label',{staticClass:"text-left"},[_vm._v("評鑑名稱 "),_c('b-form-input',{attrs:{"trim":"","disabled":_vm.eventDataIN.status === 'done' ||
                _vm.eventDataIN.status === 'ongoing'},model:{value:(_vm.eventData.name),callback:function ($$v) {_vm.$set(_vm.eventData, "name", $$v)},expression:"eventData.name"}})],1)]),_c('b-col',[_c('label',{staticClass:"text-left"},[_vm._v("起始日期 "),_c('b-form-datepicker',{staticClass:"mb-2",attrs:{"disabled":_vm.eventDataIN.status === 'done' ||
                _vm.eventDataIN.status === 'ongoing',"label-no-date-selected":"還沒選日期"},model:{value:(_vm.eventData.start_time),callback:function ($$v) {_vm.$set(_vm.eventData, "start_time", $$v)},expression:"eventData.start_time"}})],1)]),_c('b-col',[_c('label',{staticClass:"text-left"},[_vm._v("結束日期 "),_c('b-form-datepicker',{staticClass:"mb-2",attrs:{"disabled":_vm.eventDataIN.status === 'done' ||
                _vm.eventDataIN.status === 'ongoing',"label-no-date-selected":"還沒選日期"},model:{value:(_vm.eventData.end_time),callback:function ($$v) {_vm.$set(_vm.eventData, "end_time", $$v)},expression:"eventData.end_time"}})],1)])],1),_c('b-row',[_c('b-col',[_c('label',{staticClass:"text-left"},[_vm._v("主辦單位 "),_c('b-form-input',{attrs:{"trim":""},model:{value:(_vm.eventData.organizer),callback:function ($$v) {_vm.$set(_vm.eventData, "organizer", $$v)},expression:"eventData.organizer"}})],1)]),_c('b-col',[_c('label',{staticClass:"text-left"},[_vm._v("協辦單位 "),_c('b-form-input',{attrs:{"trim":""},model:{value:(_vm.eventData.coorganizer),callback:function ($$v) {_vm.$set(_vm.eventData, "coorganizer", $$v)},expression:"eventData.coorganizer"}})],1)]),_c('b-col',[_c('label',{staticClass:"text-left"},[_vm._v("評鑑模式 "),_c('b-form-select',{attrs:{"disabled":_vm.eventDataIN.status === 'done' ||
                _vm.eventDataIN.status === 'ongoing',"options":[
              { value: 'remote', text: '線上評鑑' },
              { value: 'local', text: '現場評鑑' }
            ]},model:{value:(_vm.eventData.type),callback:function ($$v) {_vm.$set(_vm.eventData, "type", $$v)},expression:"eventData.type"}})],1)])],1),_c('b-row',[_c('b-col',[_c('label',{staticClass:"text-left"},[_vm._v("指導單位 "),_c('b-form-input',{attrs:{"trim":""},model:{value:(_vm.eventData.adviser),callback:function ($$v) {_vm.$set(_vm.eventData, "adviser", $$v)},expression:"eventData.adviser"}})],1)]),_c('b-col',[_c('label',{staticClass:"text-left"},[_vm._v("承辦單位 "),_c('b-form-input',{attrs:{"trim":""},model:{value:(_vm.eventData.implementer),callback:function ($$v) {_vm.$set(_vm.eventData, "implementer", $$v)},expression:"eventData.implementer"}})],1)])],1),_c('b-row',[_c('b-col',[_c('label',{staticClass:"text-left",staticStyle:{"width":"100%"}},[_vm._v("咖啡暗碼池 "),_c('b-form-tags',{attrs:{"tag-variant":"primary","disabled":_vm.eventDataIN.status === 'done' ||
                _vm.eventDataIN.status === 'ongoing',"tag-pills":"","separator":" ","placeholder":"請直接鍵入暗碼並按enter逐一建立","remove-on-delete":""},model:{value:(_vm.eventData.beanCodePool),callback:function ($$v) {_vm.$set(_vm.eventData, "beanCodePool", $$v)},expression:"eventData.beanCodePool"}})],1)])],1),_c('b-row',[_c('b-col',[_c('label',{staticClass:"text-left",staticStyle:{"width":"100%"}},[_vm._v("評審池 "),_c('form_tags',{attrs:{"disabled":_vm.eventDataIN.status === 'done' ||
                _vm.eventDataIN.status === 'ongoing',"ratersProp":_vm.eventData.ratersPool,"options":_vm.allUsers},on:{"input":function($event){return _vm.$set(_vm.eventData, 'ratersPool', $event)}}})],1)])],1),_c('b-row',{staticClass:"mt-2",attrs:{"align-h":"start"}},[_c('b-col',{attrs:{"cols":"4"}},[_c('b-btn',{staticClass:"m-1",attrs:{"variant":"danger"},on:{"click":function($event){return _vm.$emit('quit', _vm.eventData)}}},[_vm._v(" 放棄 ")]),_c('b-btn',{staticClass:"m-1",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.$emit('save', _vm.eventData)}}},[_vm._v(" 儲存 ")])],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }