<template>
  <b-container>
    <b-breadcrumb class="mt-3" :items="$route.meta.breadcrumb"></b-breadcrumb>
    <b-pagination
      v-model="currentPage"
      :total-rows="totalRows"
      :per-page="perPage"
      align="center"
    ></b-pagination>
    <b-row cols="1" align-h="center" align-v="center">
      <b-col>
        <EventFormCard
          v-if="$store.state.me.is_admin"
          mode="create"
          @create="createEvent($event)"
        ></EventFormCard>
      </b-col>
      <template v-for="(e, index) in EventList">
        <b-col :key="index">
          <EventCard
            :eventData="e"
            v-if="EditCardStatusList[index] === 0"
            @showEventForm="EditCardStatusList.splice(index, 1, 1)"
            @delete="deleteEvent($event)"
            @trigger="eventCardTrigger(e, index)"
            @start="startEvent($event)"
          ></EventCard>
          <EventFormCard
            mode="edit"
            v-if="EditCardStatusList[index] === 1"
            :eventDataIN="e"
            @save="alterEvent($event)"
            @quit="EditCardStatusList.splice(index, 1, 0)"
          ></EventFormCard>
        </b-col>
      </template>
    </b-row>

    <b-row class="my-3" align-h="between">
      <b-col xl="3" md="5">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="center"
        ></b-pagination>
      </b-col>
      <b-col xl="3" md="4">
        <b-form-select
          v-model="perPage"
          :options="[
            { value: 5, text: '5個' },
            { value: 10, text: '10個' },
            { value: 20, text: '20個' }
          ]"
        ></b-form-select>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import EventCard from "../components/EventCard.vue";
import EventFormCard from "../components/EventFormCard.vue";
import { API } from "../store/api";
export default {
  name: "HistoryEvent",
  components: {
    EventCard,
    EventFormCard
  },
  watch: {
    async currentPage(newVal) {
      await this.getEvents(this.perPage, newVal);
    },
    async perPage(newVal) {
      await this.getEvents(newVal, this.currentPage);
    },
    EventList(newVal) {
      this.EditCardStatusList.length = newVal.length;
      this.EditCardStatusList.fill(0);
    }
  },
  mounted() {
    this.getEvents();
  },
  data() {
    return {
      currentPage: 1,
      totalRows: 0,
      perPage: 5,
      eventFormData: {},
      EditCardStatusList: [],
      EventList: []
    };
  },
  methods: {
    async getEvents(perPage = this.perPage, currentPage = this.currentPage) {
      let data = await API.GetEvents(perPage, (currentPage - 1) * perPage);
      this.totalRows = data.count;

      this.EventList = data.events;
    },
    async deleteEvent(eventID) {
      await API.DeleteEvent(eventID);
      await this.getEvents();
    },
    async createEvent({
      name,
      start_time,
      end_time,
      type,
      adviser,
      organizer,
      coorganizer,
      implementer
    }) {
      start_time += " 00:00:00";
      end_time += " 00:00:00";
      let res = await API.NewEvent({
        name,
        start_time,
        end_time,
        type,
        adviser,
        organizer,
        coorganizer,
        implementer
      });
      if (res.status === 201) {
        this.$bvToast.toast("成功建立新評鑑", {
          title: "Success Alert",
          variant: "success",
          autoHideDelay: 3000
        });
      }
      await this.getEvents();
    },
    async alterEvent({
      id,
      name,
      start_time,
      end_time,
      type,
      adviser,
      status = "editing",
      organizer,
      coorganizer,
      implementer,
      ratersPool,
      beanCodePool
    }) {
      if (beanCodePool && beanCodePool.length !== 0) {
        let res = await API.AddTargetsToEvent(id, beanCodePool);
      }
      if (ratersPool && ratersPool.length !== 0) {
        let res = await API.AddRatersToEvent(id, ratersPool);
      }
      let res = await API.PUTEvent({
        id,
        name,
        start_time,
        end_time,
        status,
        type,
        adviser,
        organizer,
        coorganizer,
        implementer
      });

      if (res.status === 201) {
        this.$bvToast.toast(`${name}成功儲存活動`, {
          title: "通知",
          variant: "success",
          autoHideDelay: 500
        });
      }

      await this.getEvents();
    },
    async startEvent({
      id,
      name,
      start_time,
      end_time,
      type,
      adviser,
      organizer,
      coorganizer,
      implementer,
      ratersPool,
      beanCodePool
    }) {
      await this.alterEvent({
        id,
        name,
        start_time,
        end_time,
        type,
        status: "ongoing",
        adviser,
        organizer,
        coorganizer,
        implementer,
        ratersPool,
        beanCodePool
      });

      await this.getEvents();
    },
    eventCardTrigger(e, index) {
      switch (e.status) {
        case "ongoing":
          this.$router.push({
            name: "EventDashboard",
            params: { eventID: e.id }
          });
          break;
        case "done":
          this.$router.push({
            name: "EventDashboard",
            params: { eventID: e.id }
          });
          break;
        case "editing":
          this.EditCardStatusList.splice(index, 1, 1);
          break;
      }
    }
  }
};
</script>

<style></style>
