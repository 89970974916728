<template>
  <!-- Prop `add-on-change` is needed to enable adding tags vie the `change` event -->
  <b-form-tags
    id="tags-component-select"
    v-model="ids"
    size="lg"
    class="mb-2"
    @input="$emit('input', raters)"
    :disabled="disabled"
    add-on-change
    no-outer-focus
  >
    <template v-slot="{ tags, inputAttrs, inputHandlers, disabled, removeTag }">
      <ul
        v-if="tags.length > 0 && ratersProp.length > 0"
        class="list-inline d-inline-block mb-2"
      >
        <li v-for="(tag, index) in tags" :key="index" class="list-inline-item">
          <b-form-tag
            :id="'formTag' + tag"
            pill
            @remove="removeTag(tag)"
            :title="'user_id:' + tag"
            :disabled="disabled"
            :variant="
              raters.find(val => val.user_id == parseInt(tag, 10)).type ===
              'head'
                ? 'warning'
                : 'info'
            "
          >
            {{
              `${options.find(opt => opt.value == tag).text} [${
                raters.find(val => val.user_id == parseInt(tag, 10)).code
              }]`
            }}
          </b-form-tag>
          <b-popover
            :target="'formTag' + tag"
            triggers="click blur"
            @shown="popoverShown($event)"
            placement="auto"
          >
            <template #title>
              <p class="mb-0">
                {{ options.find(opt => opt.value == tag).text }}
              </p>
            </template>
            <div class="d-flex">
              <label style="min-width:6rem" class="mr-2">
                類型
                <b-form-select
                  v-model="
                    raters[
                      raters.findIndex(val => val.user_id == parseInt(tag, 10))
                    ].type
                  "
                  :options="raterTypeOptions"
                ></b-form-select>
              </label>
              <label>
                暗碼
                <b-form-input
                  v-model="
                    raters[
                      raters.findIndex(val => val.user_id == parseInt(tag, 10))
                    ].code
                  "
                  :state="
                    targetCodeRepeatCheck(
                      raters.findIndex(val => val.user_id == parseInt(tag, 10))
                    )
                  "
                  placeholder="輸入暗碼"
                >
                </b-form-input>
                <b-form-invalid-feedback>
                  暗碼重覆
                </b-form-invalid-feedback>
              </label>
            </div>
          </b-popover>
        </li>
      </ul>
      <p class="text-secondary mb-1" style="font-size:0.9rem">
        <b-icon icon="exclamation-circle-fill" variant="secondary"></b-icon>
        點擊 badge 可以設定評審長及暗碼
      </p>
      <b-form-select
        :form="inputAttrs.form"
        :id="inputAttrs.id"
        :value="inputAttrs.value"
        @change="inputHandlers.change($event)"
        @input="inputHandlers.input($event)"
        @keydown="inputHandlers.keydown($event)"
        :disabled="disabled || availableOptions.length === 0"
        :options="availableOptions"
      >
        <template #first>
          <option disabled :value="null">請選擇選項...</option>
        </template>
      </b-form-select>
    </template>
  </b-form-tags>
</template>

<script>
export default {
  model: {
    prop: "ratersProp",
    event: "input"
  },
  props: {
    disabled: {
      type: Boolean,
      default() {
        return false;
      }
    },
    ratersProp: {
      type: Array,
      default() {
        return [];
      }
    },
    options: {
      type: Array,
      require: true
    }
  },
  watch: {
    ratersProp: {
      handler(newVal, oldVal) {
        if (newVal.length !== 0 && oldVal.length === 0) {
          this.raters = newVal;
          this.ids = newVal.map(r => r.user_id);
        }
      }
    },
    ids(new_ids) {
      // 列出要新增的豆子 id
      let delta_new = new_ids.filter(
        id =>
          this.raters.find(_rater => _rater.user_id === parseInt(id, 10)) ==
          undefined
      );

      delta_new.forEach(id => {
        let code;

        do {
          code = this.shuffleNums.shift() + "";
        } while (this.raters.some(t => t.code === code));

        this.raters.push({
          user_id: parseInt(id, 10),
          type: "judge",
          code: code
        });
      });

      // 列出要刪除的豆子 id
      let delta_extra = this.raters
        .filter(
          rater =>
            new_ids.find(id => parseInt(id, 10) === rater.user_id) == undefined
        )
        .map(r => r.user_id);

      delta_extra.forEach(id => {
        let index = this.raters.findIndex(r => r.user_id === id);
        this.raters.splice(index, 1);
      });
    }
  },
  mounted() {
    function shuffle(array) {
      for (let i = array.length - 1; i > 0; i--) {
        let j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
      }
      return array;
    }

    this.shuffleNums = shuffle([...Array(999).keys()]);
  },
  data() {
    return {
      shownPopoverID: null,
      shuffleNums: [],
      ids: [],
      raters: [] /*
        { user_id
          type
          code
        }
        */
    };
  },
  computed: {
    raterTypeOptions() {
      if (this.raters.some(rater => rater.type === "head")) {
        return [
          { text: "評審員", value: "judge" },
          { text: "評審長", value: "head", disabled: true }
        ];
      } else {
        return [
          { text: "評審員", value: "judge" },
          { text: "評審長", value: "head" }
        ];
      }
    },
    availableOptions() {
      return this.options.filter(opt => this.ids.indexOf(opt.value) === -1);
    }
  },
  methods: {
    popoverShown(BvEvent) {
      if (this.shownPopoverID !== null) {
        this.$root.$emit("bv::hide::popover", this.shownPopoverID);
      }
      this.shownPopoverID = BvEvent.componentId;
    },
    targetCodeRepeatCheck(index) {
      let code = this.raters[index].code;
      return this.raters
        .filter((cur, i) => i !== index)
        .every(r => r.code !== code);
    }
  }
};
</script>
