<template>
  <div>
    <div
      class="cardBody mx-auto my-2 d-none d-md-block "
      @click="$emit('trigger')"
    >
      <b-btn
        @click.stop="$emit('delete', eventData.id)"
        class="closeBtn"
        v-if="
          $store.state.me.is_admin === true && eventData.status === 'editing'
        "
      >
        <b-img src="../assets/x.svg" />
      </b-btn>

      <div class="d-flex justify-content-between align-items-center">
        <div class="d-flex flex-column align-items-start w-100">
          <div
            class="d-flex justify-content-center align-items-center flex-wrap"
          >
            <h2 class="text-left">{{ eventData.name }}</h2>
            <div class="d-flex flex-warp justify-content-start">
              <p class="ml-3 mb-0">
                {{ eventData.start_time.slice(0, 10) }}
              </p>
              <p class="mb-0">~</p>
              <p class="mb-0">
                {{ eventData.end_time.slice(0, 10) }}
              </p>
            </div>
          </div>

          <div
            class="d-flex flex-column align-items-start ml-4"
            style="min-width:15rem"
          >
            <template v-for="(key, index) in Object.keys(metaData)">
              <div :key="index" class="d-flex ">
                <p class="mb-0">{{ key + " : " }}</p>
                <p class="mb-0 ml-2 text-nowrap">{{ metaData[key] }}</p>
              </div>
            </template>
          </div>
          <div
            class="d-flex align-self-center align-content-center align-items-center flex-wrap mt-2 ml-2"
          >
            <b-btn
              v-if="$store.state.me.is_admin"
              @click.stop="$emit('showEventForm')"
              variant="primary"
              class="m-1"
              >編輯活動資訊
            </b-btn>
            <b-btn
              v-if="$store.state.me.is_admin"
              :disabled="['done'].indexOf(eventData.status) === -1"
              @click.stop="
                $router.push({
                  name: 'CoffeeBeanManagement',
                  params: { eventID: eventData.id }
                })
              "
              variant="primary"
              class="m-1"
            >
              附加咖啡豆資訊
            </b-btn>
            <b-btn
              v-if="$store.state.me.is_admin"
              :disabled="eventData.status !== 'editing'"
              @click="$emit('start', { id: eventData.id })"
              variant="primary"
              class="m-1"
            >
              開始評鑑活動
            </b-btn>

            <b-dropdown
              v-if="
                (Controllable && true) ||
                  (!Controllable && ['done'].indexOf(eventData.status) !== -1)
              "
              :disabled="
                !(
                  (Controllable &&
                    ['done', 'ongoing'].indexOf(eventData.status) !== -1) ||
                  (!Controllable && ['done'].indexOf(eventData.status) !== -1)
                )
              "
              @click.stop=""
              text="輸出列印檔"
              variant="primary"
              class="m-1"
            >
              <b-dropdown-item
                v-if="Controllable"
                :disabled="eventData.status !== 'done'"
                @click.stop="GetFile('host_export')"
                href="#"
              >
                主辦單位簽名表
              </b-dropdown-item>
              <b-dropdown-item
                v-if="Controllable"
                :disabled="['done', 'ongoing'].indexOf(eventData.status) === -1"
                @click.stop="GetFile('raters_code')"
                href="#"
              >
                評審暗碼表
              </b-dropdown-item>
              <b-dropdown-item
                :disabled="eventData.status !== 'done'"
                @click.stop="GetFile('judge_export')"
                href="#"
              >
                評審個人評分表
              </b-dropdown-item>
              <b-dropdown-item
                v-if="Controllable"
                :disabled="['done', 'ongoing'].indexOf(eventData.status) === -1"
                @click.stop="GetFile('raw_export')"
                href="#"
              >
                原始資料表
              </b-dropdown-item>
              <b-dropdown-item
                v-if="Controllable"
                :disabled="['done', 'ongoing'].indexOf(eventData.status) === -1"
                @click.stop="GetFile('tool_format')"
                href="#"
              >
                杯測小幫手輸出檔
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </div>
        <div v-if="eventData.status === 'done'" class="eventStatus-Finished">
          <p>已結束</p>
        </div>
        <div
          v-else-if="eventData.status === 'editing'"
          class="eventStatus-Editing"
        >
          <p>編輯中</p>
        </div>
        <div
          v-else-if="eventData.status === 'ongoing'"
          class="eventStatus-Underway"
        >
          <p>進行中</p>
        </div>
        <div v-else class="eventStatus-NoStatus">
          <p>無狀態</p>
        </div>
      </div>
    </div>

    <!-- mobile card -->
    <div
      class=" mobileCardBody d-flex flex-column align-content-center align-items-center d-sm-flex d-md-none mx-auto my-2"
      @click="$emit('trigger')"
    >
      <div class="d-flex flex-column justify-content-center align-items-center">
        <h2>{{ eventData.name }}</h2>
        <p>
          {{
            eventData.start_time.slice(0, 10) +
              " ~ " +
              eventData.end_time.slice(0, 10)
          }}
        </p>
      </div>

      <div class="d-flex flex-column align-items-start ">
        <template v-for="(key, index) in Object.keys(metaData)">
          <div :key="index" class="d-flex ">
            <p class="mb-0 text-nowrap">{{ key + " : " }}</p>
            <p class="mb-0 ml-2 ">{{ metaData[key] }}</p>
          </div>
        </template>
      </div>

      <div v-if="eventData.status === 'done'" class="eventStatus-Finished my-3">
        <p>已結束</p>
      </div>
      <div
        v-else-if="eventData.status === 'editing'"
        class="eventStatus-Editing my-3"
      >
        <p>編輯中</p>
      </div>
      <div
        v-else-if="eventData.status === 'ongoing'"
        class="eventStatus-Underway my-3"
      >
        <p>進行中</p>
      </div>
      <div v-else class="eventStatus-NoStatus">
        <p>無狀態</p>
      </div>

      <b-btn
        v-if="eventData.status === 'done'"
        @click.stop="GetFile('judge_export')"
        variant="primary"
      >
        評審個人評分表下載
      </b-btn>
    </div>
  </div>
</template>

<script>
import { API } from "../store/api";
export default {
  name: "EventCard",
  props: {
    eventData: {
      type: Object,
      require: true
    }
  },
  computed: {
    metaData() {
      return {
        主辦單位: this.eventData.organizer,
        協辦單位: this.eventData.coorganizer,
        指導單位: this.eventData.adviser,
        承辦單位: this.eventData.implementer
      };
    },
    Controllable() {
      let is_headJudge = false;
      let rater = this.eventData.raters.find(
        r => r.user_id === this.$store.state.me.id
      );
      if (rater) {
        is_headJudge = rater.type === "head";
      }

      return this.$store.state.me.is_admin || is_headJudge;
    }
  },
  methods: {
    async GetFile(FileCode) {
      let baseAddr = process.env.VUE_APP_AGRI_API_PATH;
      let getUrl = window.location;

      if (
        [
          "host_export",
          "raters_code",
          "judge_export",
          "raw_export",
          "tool_format"
        ].indexOf(FileCode) !== -1
      ) {
        let data = await API.GetFile(this.eventData.id, FileCode);
        let FileToken = data.file_token;
        window.open(
          `${getUrl.protocol}//${getUrl.host}${baseAddr}api/files?file_token=${FileToken}`,
          "_blank"
        );
      }
    }
  },
  data() {
    return {};
  }
};
</script>

<style scoped lang="scss">
.cardBody {
  position: relative;
  max-width: 1027px;

  padding: 1rem 2rem 1rem 1.5rem;
  box-sizing: border-box;
  background: linear-gradient(135deg, #ededed 0%, #ffffff 100%);
  box-shadow: -8px 8px 16px rgba(165, 165, 165, 0.2),
    8px -8px 16px rgba(165, 165, 165, 0.2),
    -8px -8px 16px rgba(255, 255, 255, 0.9),
    8px 8px 20px rgba(165, 165, 165, 0.9),
    inset 1px 1px 2px rgba(255, 255, 255, 0.3),
    inset -1px -1px 2px rgba(165, 165, 165, 0.5);

  border-radius: 7px;

  &:hover {
    background: linear-gradient(135deg, #ffffff 0%, #ededed 100%);
  }
}

.mobileCardBody {
  max-width: 320px;
  box-sizing: border-box;
  border-radius: 7px;
  background: linear-gradient(135deg, #ededed 0%, #ffffff 100%);
  box-shadow: -8px 8px 16px rgba(165, 165, 165, 0.2),
    8px -8px 16px rgba(165, 165, 165, 0.2),
    -8px -8px 16px rgba(255, 255, 255, 0.9),
    8px 8px 20px rgba(165, 165, 165, 0.9),
    inset 1px 1px 2px rgba(255, 255, 255, 0.3),
    inset -1px -1px 2px rgba(165, 165, 165, 0.5);

  padding: 1rem 1rem;
}

.closeBtn {
  background: #eb5757;
  border-radius: 4px;

  position: absolute;
  width: 26px;
  height: 26px;
  right: 19px;
  top: 17px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.eventStatus {
  border: 2px solid rgba(64, 64, 64, 0.25);
  box-sizing: border-box;
  box-shadow: 2px 2px 4px rgba(235, 87, 87, 0.25);
  border-radius: 7px;
  min-width: 12rem;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 20px 35px;

  p {
    margin: 0rem 0rem 0.2rem 0rem;
    font-size: 36px;
    line-height: 18px;
    color: #ffffff;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
}

.eventStatus-Finished {
  @extend .eventStatus;
  background: #eb5757;
}

.eventStatus-Editing {
  @extend .eventStatus;
  background: #80bdff;
}

.eventStatus-Underway {
  @extend .eventStatus;
  background: #219653;
}

.eventStatus-NoStatus {
  @extend .eventStatus;
  background: #636363;
}
</style>
